import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISelectListItemDto } from 'app/models';
import { HistorySource } from 'app/models/enums/history';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHistoryV2Dto } from './dto/history-v2-dto';

@Injectable()
export class HistoryV2DataService {
    public id$ = new ReplaySubject<{ id: string; source: number }>();
    public detailBreadCrumbs: string;

    baseApiRoute = 'api/history/v2';

    constructor(private readonly http: HttpClient) {}

    public getEntityTypeEnum(source: HistorySource): Observable<Array<ISelectListItemDto>> {
        return this.http.get<Array<ISelectListItemDto>>(`${this.baseApiRoute}/${source}/allowed-types`);
    }

    public getUsers(source: HistorySource): Observable<Array<ISelectListItemDto>> {
        return this.http.get<Array<string>>(`${this.baseApiRoute}/${source}/users`).pipe(
            map((res) => {
                return res.map((x) => ({
                    id: null,
                    text: x
                }));
            })
        );
    }

    public getHistory(id: string, source: HistorySource): Observable<IHistoryV2Dto> {
        return this.http.get<IHistoryV2Dto>(`${this.baseApiRoute}/${source}/${id}`);
    }
}
